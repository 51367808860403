import React, { useState } from "react";
import * as S from "./styles";
import Flex from "../../common/Flex";
import { TokenImg } from "../Header/styles";
import { CupIcon, FormatGold, GoldCoin, ProfileIcon, TeamGold, WalletGold } from "../../../../assets/svg";
import moment from "moment";

export const MultiStage = ({ data: stages, tournamentData, joinMode, setStageId, setActiveButton, extraInfo }) => {
  const [stageInformation, setStageInformation] = useState(stages.find(stage => stage.isActive) || stages[0]);
  const [isClicked, setIsClicked] = useState(false);

  const onClickChangeStage = (activeButton, stageId, stageData, index) => {
    setIsClicked(true);
    setStageInformation(stageData);
    setStageId(activeButton, stageId, index);
    setActiveButton(activeButton);
    setIsClicked(false);
  };

  const stageData = [
    {
      id: "prize-pool",
      icon: <GoldCoin />,
      title: "Prize Pool",
      description: `${tournamentData?.poolPrize ?? "-"}`,
    },
    {
      id: "entry-fee",
      icon: <WalletGold />,
      title: "Entry fee",
      description: `${tournamentData?.entrance || "Free Entry"}`,
    },
    {
      id: "team-mode",
      icon: <TeamGold />,
      title: "Team mode",
      description: `${
        tournamentData?.joinMode === "TEAM" ? `Teams of ${tournamentData?.gameDetails?.teamUsersQty}` : "Solo"
      }`,
    },
    {
      id: "format",
      icon: <FormatGold />,
      title: "Format",
      description: `${
        stageInformation?.stageMode === "LEADERBOARD"
          ? "Leaderboard"
          : stageInformation?.stageMode === "BEST_OF_3"
          ? "Best of 3"
          : stageInformation?.stageMode === "BEST_OF_5"
          ? "Best of 5"
          : stageInformation?.stageMode === "ROUND_ROBIN"
          ? "Round robin"
          : ""
      }`,

      // replace with the respective data
    },
    {
      id: "type",
      icon: <CupIcon width="20px" height="16px" />,
      title: "Type",
      description: `${tournamentData?.secBannerInfo?.type}`,
    },
  ];

  return (
    <S.Container>
      <S.SelectPillWrapper>
        {stages?.map((stage, index) => {
          return (
            <S.SelectPill
              key={stage.stageId}
              selected={stageInformation?.stageId === stage?.stageId}
              disabled={isClicked}
              onClick={() => {
                if (stageInformation?.stageId === stage.stageId) return;
                onClickChangeStage(stage.optionBtns[0], stage.stageId, stage, index);
              }}
            >
              <S.SelectPillText>{index === stages.length - 1 ? "Final" : `Stage ${stage?.stageId}`}</S.SelectPillText>
            </S.SelectPill>
          );
        })}
      </S.SelectPillWrapper>
      <S.StageContainer>
        <S.StageDataWrapper>
          <div>
            <S.StageInfoWrapper>
              <S.DescriptionText>{stageInformation?.description}</S.DescriptionText>
              <div>
                {stageInformation?.status === "RUNNING" ? (
                  <Flex alignItems="center" gap="5">
                    <S.PlayingDot>
                      <div>
                        <span />
                      </div>
                    </S.PlayingDot>
                    <S.StageStatusText>Playing</S.StageStatusText>
                  </Flex>
                ) : stageInformation?.status === "JOINING" ? (
                  <S.StageStatusText>Registration Open</S.StageStatusText>
                ) : stageInformation?.status === "FINISHED" ? (
                  <S.StageStatusText>Finished</S.StageStatusText>
                ) : (
                  <S.StageStatusText>Soon</S.StageStatusText>
                )}
              </div>
            </S.StageInfoWrapper>
          </div>
          <S.PoolDateWrapper>
            <S.DateStatusWrapper>
              <S.DateStatus stageTimeFinish={Date.now() > stageInformation?.startDate} />
              <S.DateInfoText>Start</S.DateInfoText>
              <S.DateText>{moment(stageInformation?.startDate).format("MMM DD, h:mm A")}</S.DateText>
            </S.DateStatusWrapper>
            <S.DateLineWrapper>
              <S.DateLine />
            </S.DateLineWrapper>
            <S.DateStatusWrapper>
              <S.DateStatus stageTimeFinish={Date.now() > stageInformation?.endDate} />
              <S.DateInfoText>End</S.DateInfoText>
              <S.DateText>{moment(stageInformation?.endDate).format("MMM DD, h:mm A")}</S.DateText>
            </S.DateStatusWrapper>
          </S.PoolDateWrapper>
          <S.PlayerAmmountWrapper>
            <ProfileIcon />
            <S.PlayerAmmountText>{extraInfo?.playersQty} {joinMode !== "TEAM" ? "Players" : "Teams"} </S.PlayerAmmountText>
          </S.PlayerAmmountWrapper>
        </S.StageDataWrapper>
        <S.PoolDataWrapper>
          {stageData.map(data => {
            return (
              <S.PoolInfoContainer id={data.id}>
                {data?.icon}
                <S.PoolInfoWrapper>
                  <S.PoolInfoTitle>{data?.title}</S.PoolInfoTitle>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <S.PoolInfoData>{data?.description || "N/A"}</S.PoolInfoData>
                    {data.id === "prize-pool" ? (
                      <>
                        {tournamentData?.tokenImage ? (
                          <TokenImg src={tournamentData?.tokenImage} />
                        ) : (
                          tournamentData?.tokenSymbol
                        )}
                      </>
                    ) : data.id === "entry-fee" ? (
                      <>
                        {tournamentData?.entranceTokenImage ? (
                          <TokenImg src={tournamentData?.entranceTokenImage} />
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </S.PoolInfoWrapper>
              </S.PoolInfoContainer>
            );
          })}
        </S.PoolDataWrapper>
      </S.StageContainer>
    </S.Container>
  );
};
