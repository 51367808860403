import React, { useState, useRef, useEffect } from "react";
import * as S from "./styles";
import { LeaderboardArrowGrayIcon, DottedMenu /* OkTick */ } from "../../../../../assets/svg";
import Flex from "../../../common/Flex";
import { useGetSecHeaders } from "../../../../../hooks/SecurityHeaders";
import { Skeleton } from "antd";
import DetailModal from "../DetailModal";
import ReportModal from "./ReportModal";
import { EPHERE_GAME_ID, KOINS_GAME_ID } from "../../../../../constants";

function returnString(str = "") {
  if (str === 0) {
    return 0;
  } else if (str === "") {
    return "-";
  } else {
    return str;
  }
}

export default function PlayerRow({
  item,
  color,
  icon,
  isMobile,
  dynamicHeaders,
  gameId,
  colCountMap,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
  isLoading,
}) {
  const headers = useGetSecHeaders();
  const dotMenuRef = useRef({});
  // const [showMore, setShowMore] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showReportToggleMenu, setShowReportToggleMenu] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  let order;
  switch (item.order) {
    case 1:
      order = "1st";
      break;
    case 2:
      order = "2nd";
      break;
    case 3:
      order = "3rd";
      break;
    default:
      order = `${item.order}th`;
  }

  // const ref = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setShowMore(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [ref]);

  // const onClick = () => {
  //   setShowMore(prev => !prev);
  // };

  const openReportModal = e => {
    e.stopPropagation();
    setShowReportModal(true);
    setShowReportToggleMenu(false);
  };

  const toggleReportModal = () => {
    setShowReportModal(prev => !prev);
  };
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showReportToggleMenu && dotMenuRef.current && !dotMenuRef.current.contains(e.target)) {
        setShowReportToggleMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showReportToggleMenu]);

  const toggleDetailModal = () => {
    setShowDetailModal(prev => !prev);
  };

  const toggleReportMenu = e => {
    setShowReportToggleMenu(true);
    e.stopPropagation();
  };
  return (
    <>
      <S.PlayerRowWrapper
        currentUser={
          joinMode === "TEAM"
            ? item?.playerData?.players?.findIndex(player => player.userId === headers.userId) !== -1
            : item.userId === headers.userId // TODO: [changeToUserId] IMPORTANT!! Ensure we get the userId property here from the backend.
        }
        isMobile={isMobile}
      >
        <Skeleton loading={isLoading} active avatar title={false} paragraph={{ rows: 1 }} style={{ margin: "0 9px" }}>
          <S.PlayerInfoWrapper
            onClick={() => {
              if (hasTemplate) {
                selectPlayerForTemplate(item);
              } else {
                setShowDetailModal(true);
              }
            }}
            isMobile={isMobile}
            colCount={dynamicHeaders ? (isMobile ? 5 : colCountMap?.[item?.gameId] || dynamicHeaders?.length) : ""}
          >
            {!(item.gameId === EPHERE_GAME_ID) && (
              <S.Flex style={{ alignItems: "center" }}>
                {!isMobile && (
                  <S.ArrowWrapper color={color}>
                    <LeaderboardArrowGrayIcon />
                  </S.ArrowWrapper>
                )}
                <S.Flex>
                  <S.WinnerWrapper>
                    {!isMobile && <span>{icon}</span>}
                    {icon ? (
                      <S.OrderText color={color} win>
                        {order}
                      </S.OrderText>
                    ) : (
                      <S.OrderText color={color}>{item.order}</S.OrderText>
                    )}
                  </S.WinnerWrapper>
                </S.Flex>
              </S.Flex>
            )}
            {item.gameId === KOINS_GAME_ID ? (
              <>
                {isMobile
                  ? ["id", "name", "races", "score"].map(header => (
                      <S.NameWrapper>
                        <S.ScoreText
                          style={{
                            marginLeft: "20px",
                            width: "50px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.playerData[header] || 0}
                        </S.ScoreText>
                      </S.NameWrapper>
                    ))
                  : dynamicHeaders?.map(header => (
                      <S.NameWrapper>
                        <S.ScoreText>
                          {returnString(item?.bestMatch?.[header?.dataKey] ?? item?.[header?.dataKey]) ?? "-"}
                        </S.ScoreText>
                      </S.NameWrapper>
                    ))}
              </>
            ) : item.gameId === EPHERE_GAME_ID ? (
              <>
                <S.NameWrapper style={{ gridColumn: "span 3" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <S.OrderText style={{ marginRight: "12px", marginLeft: "10px", fontSize: "18px", color }}>
                      {item.order}
                    </S.OrderText>
                    <S.AvatarImage src={item?.thumbnail || item?.avatarURL} />
                    <S.NameText>{item.nick}</S.NameText>
                  </div>
                </S.NameWrapper>
                {isMobile ? (
                  <S.NameWrapper>
                    <S.ScoreText>
                      {item?.bestMatch?.[dynamicHeaders[0]?.dataKey] ?? item?.[dynamicHeaders[0]?.dataKey] ?? "-"}
                    </S.ScoreText>
                  </S.NameWrapper>
                ) : (
                  dynamicHeaders?.map(header => (
                    <S.NameWrapper>
                      <S.ScoreText>
                        {returnString(item?.bestMatch?.[header?.dataKey] ?? item?.[header?.dataKey]) ?? "-"}
                      </S.ScoreText>
                    </S.NameWrapper>
                  ))
                )}
              </>
            ) : (
              <>
                <S.NameWrapper>
                  <Flex alignItems="center">
                    <S.AvatarImage src={item?.thumbnail || item?.avatarURL} />
                    <S.ScoreText>{item?.name ?? item?.nick ?? "-"}</S.ScoreText>
                  </Flex>
                </S.NameWrapper>
                {dynamicHeaders ? (
                  isMobile ? (
                    <S.ScoreText>
                      <S.ScoreText isMobile>
                        {returnString(
                          item?.bestMatch?.[dynamicHeaders[0]?.dataKey] ?? item?.[dynamicHeaders[0]?.dataKey],
                        ) ?? `-`}
                      </S.ScoreText>
                    </S.ScoreText>
                  ) : (
                    dynamicHeaders?.map(header => (
                      <S.NameWrapper>
                        <Flex alignItems="center">
                          <S.ScoreText>
                            {returnString(
                              item?.bestMatch?.[header?.dataKey] ??
                                item?.[header?.dataKey] ??
                                item?.bestMatch?.[header?.value] ??
                                item?.[header?.value],
                            ) ?? `-`}
                          </S.ScoreText>
                        </Flex>
                      </S.NameWrapper>
                    ))
                  )
                ) : (
                  <>
                    <S.NameWrapper>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <S.AvatarImage src={item?.thumbnail || item?.avatarURL} />
                        <S.NameText>{item?.nick}</S.NameText>
                      </div>
                    </S.NameWrapper>
                    <S.NameWrapper>
                      <S.ScoreText style={{ textAlign: "right" }}>
                        {item?.bestMatch?.totalPoints ? Math.round(item?.bestMatch?.totalPoints) : "-"}
                      </S.ScoreText>
                    </S.NameWrapper>
                    {!isMobile && (
                      <>
                        <S.NameWrapper>
                          <S.ScoreText style={{ textAlign: "right" }}>{item?.bestMatch?.kills ?? "-"}</S.ScoreText>
                        </S.NameWrapper>
                        <S.NameWrapper>
                          <S.ScoreText style={{ textAlign: "right" }}>
                            {item?.bestMatch?.teamPlacement || "-"}
                          </S.ScoreText>
                        </S.NameWrapper>
                        <S.NameWrapper>
                          <S.ScoreText style={{ textAlign: "right" }}>{item?.bestMatch?.kdRatio ?? "-"}</S.ScoreText>
                        </S.NameWrapper>
                        <S.NameWrapper>
                          <S.ScoreText style={{ textAlign: "right" }}>
                            {item.bestMatch?.damageDone && item.bestMatch?.damageTaken
                              ? `${Math.floor(item.bestMatch?.damageDone)} / ${Math.floor(item.bestMatch?.damageTaken)}`
                              : item.bestMatch?.damageDone
                              ? Math.floor(item.bestMatch?.damageDone)
                              : "-"}
                          </S.ScoreText>
                        </S.NameWrapper>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {headers.token && item.gameId != 6 && (
              <S.DotsWrapper>
                <S.DottedMenuCotnainer ref={dotMenuRef}>
                  <span style={{ padding: "8px", height: "8px", width: "8px" }} onClick={toggleReportMenu}>
                    <DottedMenu style={{ transform: "rotate(90deg)" }} />
                  </span>
                  {showReportToggleMenu && (
                    <S.MenuContent onClick={e => e.stopPropagation()}>
                      <button onClick={openReportModal}>{joinMode === "TEAM" ? "Report Team" : "Report Player"}</button>
                    </S.MenuContent>
                  )}
                </S.DottedMenuCotnainer>
              </S.DotsWrapper>
            )}
          </S.PlayerInfoWrapper>
        </Skeleton>
        <DetailModal isOpen={showDetailModal} toggleModal={toggleDetailModal} item={item} joinMode={joinMode} />
      </S.PlayerRowWrapper>
      <ReportModal
        showReportModal={showReportModal}
        toggleReportModal={toggleReportModal}
        // playerAddress={item.address || ""}
        userId={item.userId || ""}
      />
    </>
  );
}
