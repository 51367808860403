import React from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import { breakpoints } from "../../../../themes";
import Avatar from "../../common/Avatar";

const PlayerBlockContainer = styled.div`
  width: calc(100% / 2 - 13px);
  height: 59px;
  background-color: #342788;
  opacity: ${props => (props.isOnWaitlist ? "0.8" : "1")};
  padding: 0px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  img {
    height: 35px;
    width: 35px;
    border-radius: 500px;
    margin: 0 20px;
  }
  @media (max-width: 852px) {
    width: 100%;
  }
  ${breakpoints.up(breakpoints.laptopL)} {
    width: calc(100% / 3 - 13px);
  }
`;

const PlayerDetails = styled.div`
  display: flex;
  align-items: center;
  small {
    color: #877add;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  span {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const StatusWrapper = styled.div`
  background-color: #3f2faa;
  padding: 5px 10px;
  border-radius: 14.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & span {
    color: #ffffff;
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const PlayerBlock = ({ nickname, avatar, ind, isOnWaitlist = false, isQualified = false, isLoading }) => {
  return (
    <PlayerBlockContainer isOnWaitlist={isOnWaitlist}>
      <Skeleton loading={isLoading} avatar={{ shape: "circle" }} title={false} paragraph={{ rows: 2 }} active>
        <PlayerDetails>
          <small>{ind + 1}</small>
          <Avatar src={avatar} alt="User Avatar" />
          <span>{nickname}</span>
        </PlayerDetails>
      </Skeleton>
      {(isOnWaitlist || isQualified) && (
        <StatusWrapper>
          <span>{isOnWaitlist ? "Waitlist" : "Qualified"}</span>
        </StatusWrapper>
      )}
    </PlayerBlockContainer>
  );
};
