import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import bgHome from "../assets/bg-home.png";
// import { Button } from "../components/v2/common/Button";
import CardGroup from "../components/v2/Home/CardGroup";
import TopWinnersList from "../components/v2/Home/TopWinners/TopWinnersList";
import PoolTournamentList from "../components/v2/Home/PoolTournamentList";
import PushNotifications from "../components/v2/PushNotifications";
import { sessionState } from "../state/globalState";
import { useAppState } from "../providers/AppStateProvider";
import { useWallet } from "../providers/WalletProvider";
import { breakpoints } from "../themes";
import Loader from "../components/v2/Loader";
import callAxios from "../hooks/Axios";
import { ENDPOINT } from "../constants/endpoints";
import GameCard from "../components/v2/gameSection";
import UserActivity from "./ProfilePage/UserActivity";
import { useGetSecHeaders } from "../hooks";
import { useHistory } from "react-router-dom";
import { PromotedTournamentBanner } from "../components/v2/Home/PromotedTournamentsBanner/PromotedTournamentsBanner";
import { useWindowSize } from "../hooks/useWindowSize";

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
`;

const AdSection = styled.div`
  height: 200px;
  width: 100%;
  position: relative;
  margin-bottom: 28px;
  ${breakpoints.down(breakpoints.laptop)} {
    height: 210px;
  }
  & {
    img {
      position: absolute;
      top: -110px;
      left: 0;
      width: 100%;
    }
  }
`;

const AdWrap = styled.div``;

const AdjustPadding = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 72px 22px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 22px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 20px 22px 20px;
  }
`;

const AdjustCardsPadding = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 72px 22px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 22px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 20px 22px 20px;
  }
`;

const MyEarnings = styled.div`
  background: linear-gradient(268.01deg, #5487f4 32.21%, #ac83ff 100.51%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 36px;
  line-height: 36px;
`;

const MyEarningsWrapLoggedIn = styled.div`
  margin-top: 20px;
  ${breakpoints.down(breakpoints.laptop)} {
    margin-top: 10px;
  }
`;

const EarningsLabel = styled.p`
  color: #d1ccef;
  font-size: 16px;
  line-height: 36px;
  margin-bottom: 0px;
`;

const CardSection = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 60px;
`;

const TournamentListWrap = styled.div`
  width: 100%;
  margin: auto;
`;

const PoolSectionContainer = styled.div`
  border-radius: 10px;
`;

const TopWinnersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const TopWinnersWrap = styled.div`
  width: 100%;
  margin: auto;
`;

const PushNotificationsContainer = styled.div`
  width: 100%;
  padding-top: 66px;
  padding-bottom: 66px;
  border-radius: 10px;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

const PushNotificationsWrap = styled.div`
  width: 100%;
  margin: auto;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 100%;
`;

const GameWrap = styled.div`
  width: 100%;
  margin: auto;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-bottom: 150px;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LinkText = styled.a`
  :hover {
    color: #be7eff;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GameListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  padding-top: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SectionTitle = styled.h3`
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 14px;
`;

const GameLink = styled.a`
  display: flex;
  justify-content: center;
  ${props =>
    props.isActive
      ? `justify-content: space-between; & img { position: relative; right: 50%; transform: translateX(50%); } & span { content: ''; width: 6px; height: 49px; background: #A77EFF; border-top-right-radius: 25px; border-bottom-right-radius: 25px; }`
      : `&span {display: none;}`};
`;

export default function LobbyPage() {
  const session = useRecoilValue(sessionState);
  const { loadWeb3Modal, web3Modal } = useWallet();
  const { detectMetamask } = useAppState();
  const history = useHistory();
  // warning ignored since i think that it's related to load the web3 modal
  // eslint-disable-next-line no-unused-vars
  const connectWeb3 = () => {
    detectMetamask(loadWeb3Modal);
  };

  const [timeElapsed, setTimeElapsed] = useState(false);
  const headers = useGetSecHeaders();

  // The component renders twice due to the signin funcitonality.
  // This causes the PoolTournamentList component to render twice which causes a warning. A setTimeout workaround will cause the PoolTournamentList component to render only once and remove the warning.
  useEffect(() => {
    const elapsedItnerval = setTimeout(() => {
      setTimeElapsed(true);
    }, 800);

    return () => {
      clearInterval(elapsedItnerval);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("discordConnectState") && headers.encryptedHeader) {
      history.push("/profile");
    }
  }, [headers.encryptedHeader]);

  const [bannerArray, setBannerArray] = useState([]);
  const [topWinners, setTopWinners] = useState([]);
  const [userEarnings, setUserEarnings] = useState(null);
  const [isLoadingUserEarnings, setIsLoadingUserEarnings] = useState(false);
  const [hasEarningsLoaded, setHasEarningsLoaded] = useState(false);

  /**
   * The function `getBannerImages()` is an async function that calls an API and returns an array of image URLs.
   * and updates the state with the response data if it is not empty.
   */
  const getBannerImages = async () => {
    try {
      const apiCall = await callAxios({ method: "post", url: `${ENDPOINT.ADVERTISEMENT_DASHBOARD}` });
      const apiResponse = apiCall?.data?.data;
      if (apiResponse.length > 0) {
        setBannerArray(apiResponse);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const [GameList, setGameList] = useState([]);
  const getGameList = async () => {
    try {
      const response = await callAxios({
        method: "GET",
        url: `${ENDPOINT.GAME}`,
      });
      setGameList(response?.data || []);
    } catch (error) {
      console.log({ error });
    }
  };
  const getUserEarnings = async () => {
    if (!headers.encryptedHeader) return;
    setIsLoadingUserEarnings(true);
    try {
      const response = await callAxios({
        method: "GET",
        url: `/user/myEarnings`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response && response.data && response.data?.myEarnings) {
        setUserEarnings(response?.data?.myEarnings);
        setHasEarningsLoaded(true);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoadingUserEarnings(false);
    }
  };
  const poolsSectionRef = useRef(null);
  const scrollToPoolsSection = () => {
    if (!poolsSectionRef && poolsSectionRef.current) return;
    poolsSectionRef.current.scrollIntoView({
      top: 1000,
      behavior: "smooth",
    });
  };

  const screenWidth = useWindowSize().width;
  const getTopWinners = async () => {
    try {
      const getTopWinnersApiCall = await callAxios({
        method: "GET",
        url: `${ENDPOINT.TOP_WINNERS}`,
      });
      const apiResponse = getTopWinnersApiCall?.data?.data?.topPrizers;
      if (apiResponse.length > 0) {
        //based on any screen resolution we are showing n top winners that completes the row, each card has a width of 205px and gap of 27px, so we need to complete 1fr row (it needs just to be one row), the amount of cards returned it must generate just one row of cards
        // window.screen.width must be the screen width minus the width of both sidebars
        // 205 is the width of the card
        // 27 is the gap between cards
        // 1fr is the width of the row
        // 91px is the width of one of the sidebars
        // 87px is the width of the other sidebar
        // the container has a padding-right of 72px and a left one of 47px
        const amountOfCards = Math.floor((screenWidth - 91 - 87 - 72 - 47) / (205 + 27));
        if (amountOfCards > 0 && apiResponse.length > 0) {
          const topWinnersArray = apiResponse.slice(0, amountOfCards);
          setTopWinners(topWinnersArray);
        } else {
          setTopWinners([]);
        }
      } else {
        setTopWinners([]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getBannerImages();
    getGameList();
  }, []);

  useEffect(() => {
    getTopWinners(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    // The useEffect was running multiple times, due to the dependency array maybe.
    // Therefore need to use a flag to ensure it only runs once when the headers are available
    if (headers.encryptedHeader && !hasEarningsLoaded) {
      getUserEarnings();
    }
  }, [headers.encryptedHeader]);

  return (
    <MainContainer>
      <SectionContainer>
        {bannerArray.length > 0 && (
          <AdjustPadding>
              <PromotedTournamentBanner bannerArray={bannerArray} />
          </AdjustPadding>
        )}
        <div ref={poolsSectionRef}>{headers.token && <UserActivity setUserEarnings={setUserEarnings} />}</div>
        <AdjustCardsPadding>
          <TournamentListWrap>
            <PoolSectionContainer>
              <SectionTitle>Tournaments</SectionTitle>
              {!timeElapsed ? (
                <LoaderWrapper>
                  <Loader animation="rotate" />
                </LoaderWrapper>
              ) : (
                <PoolTournamentList />
              )}
            </PoolSectionContainer>
          </TournamentListWrap>
        </AdjustCardsPadding>
        <AdjustCardsPadding>
          {topWinners.length > 0 && (
            <TopWinnersWrap>
              <TopWinnersContainer>
                <SectionTitle>Top Winners</SectionTitle>
                <TopWinnersList topWinners={topWinners} />
              </TopWinnersContainer>
            </TopWinnersWrap>
          )}
        </AdjustCardsPadding>
        <AdjustCardsPadding>
          <SectionTitle>Statistics</SectionTitle>
          <CardSection>
            <CardGroup
              scrollToPoolsSection={scrollToPoolsSection}
              isLoggedIn={Boolean(headers?.token)}
              userEarnings={userEarnings}
              isLoadingUserEarnings={isLoadingUserEarnings}
            />
          </CardSection>
        </AdjustCardsPadding>
        <AdjustCardsPadding>
          <GameWrap>
            <TextContainer>
              <SectionTitle>Games</SectionTitle>
              <LinkText href="/#/games">View All</LinkText>
            </TextContainer>
            <GameListContainer>
              {GameList?.map(item => (
                <GameLink href={`/#/gamepage/${item?.id}`} rel="noreferrer">
                  <GameCard gameName={item?.visibleName} imageUrl={item?.assets?.gameThumbnail} />
                </GameLink>
              ))}
            </GameListContainer>
          </GameWrap>
        </AdjustCardsPadding>
      </SectionContainer>
    </MainContainer>
  );
}
