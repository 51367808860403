import React from "react";
import { Button } from "../../common/Button";
import { BLAST_DISCORD, BLAST_POOL_URL, GAME_IDS } from "../../../../constants";
import { AxieHole, CsGoHole, ExplosionHole, LeagueOfLegendHole, PubgHole } from "../../../../assets/svg";
import * as S from "./styles";

const GameImage = ({ gameId }) => {
  let _gameId = Number(gameId);
  if (Number.isNaN(_gameId)) {
    return <ExplosionHole />;
  }
  if (_gameId === GAME_IDS.AXIE_INFINITY) {
    return <AxieHole />;
  } else if (_gameId === GAME_IDS.CS_GO) {
    return <CsGoHole />;
  } else if (_gameId === GAME_IDS.LEAGUE_OF_LEGENDS) {
    return <LeagueOfLegendHole />;
  } else if (_gameId === GAME_IDS.PUBG) {
    return <PubgHole />;
  } else return <ExplosionHole />;
};

const getNoTournamentsMessage = (activeFilter = "") => {
  const activeFilterLowerCase = activeFilter.toLowerCase();
  if (activeFilterLowerCase === "all") return `We do not have any tournaments to display right now`;
  else if (activeFilterLowerCase === "my tournaments") return `You have not joined any tournaments yet.`;
  else if (activeFilterLowerCase === "active") return `You don't have recent activities`;
  else return `We do not have any ${activeFilterLowerCase} tournaments right now`;
};

const NoTournamentFound = ({ gameId, activeFilter }) => {
  return (
    <S.Container>
      <GameImage gameId={gameId} />
      <div>
        <S.Header>{getNoTournamentsMessage(activeFilter)}</S.Header>
      </div>
    </S.Container>
  );
};

export default NoTournamentFound;
