/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0px 9px;
  background-color: #312c83;
  color: #877add;
  border: none;
  cursor: pointer;
  &.active {
    background-color: #3f2faa;
    color: #ffffff;
  }
`;

const Arrow = styled.button`
  width: 36px;
  height: 36px;
  background-color: #312c83;
  border: none;
  color: #877add;
  border-radius: 50%;
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const PageNumbersContainer = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: center;
  @media (max-width: 450px) {
    min-width: 200px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Pagination({ data, RenderComponent, pageLimit, dataLimit, isMobile = false }) {
  const [pages] = useState(Math.ceil(data?.length / dataLimit || 1));
  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    setCurrentPage(page => (page >= pages ? page : page + 1));
  }

  function goToPreviousPage() {
    setCurrentPage(page => (page <= 1 ? page : page - 1));
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    const start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const group = getPaginationGroup();

  return (
    <Container>
      <div style={{ marginBottom: "48px" }}>
        {getPaginatedData()?.map((d, idx) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <RenderComponent key={idx} {...d} isMobile={isMobile} />
        ))}
      </div>

      <ButtonContainer>
        {!group.includes(1) && (
          <div style={{ marginRight: "14px" }}>
            <Arrow
              type="button"
              onClick={() => setCurrentPage(1)}
              className={`prev ${currentPage === 1 ? "disabled" : ""}`}
            >
              &lsaquo;&lsaquo;&lsaquo;
            </Arrow>
          </div>
        )}
        <Arrow
          type="button"
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? "disabled" : ""}`}
          style={{ marginRight: "24px" }}
        >
          &laquo;
        </Arrow>

        <PageNumbersContainer>
          {pages > 0 ? (
            group.map((item, index) => {
              return (
                item <= pages && (
                  <Button
                    key={index}
                    onClick={changePage}
                    type="button"
                    className={`paginationItem ${currentPage === item ? "active" : null} `}
                  >
                    <span>{item}</span>
                  </Button>
                )
              );
            })
          ) : (
            <Button onClick={changePage} type="button" className="active">
              <span>1</span>
            </Button>
          )}
        </PageNumbersContainer>

        <Arrow
          type="button"
          onClick={goToNextPage}
          className={`next ${currentPage === pages || pages === 0 ? "disabled" : ""}`}
          style={{ marginLeft: "24px" }}
        >
          &raquo;
        </Arrow>
        {!group.includes(pages) && (
          <div style={{ marginLeft: "14px" }}>
            <Arrow type="button" onClick={() => setCurrentPage(pages)}>
              &rsaquo;&rsaquo;&rsaquo;
            </Arrow>
          </div>
        )}
      </ButtonContainer>
    </Container>
  );
}
