import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import PromotedTournamentCard from "../../Tournament/PromotedTournamentCard";
import TournamentCard from "../../Tournament/TournamentCard";
import NoTournamentFound from "../../Tournament/NoTournamentFound";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import Loader from "../../Loader";
import { breakpoints } from "../../../../themes";

const TournamentListContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  min-height: 325px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${breakpoints.up(breakpoints.tablet)} {
    display: grid;
    grid-template-columns: ${props =>
      !props.isLoading && props.length >= 1 ? "repeat(auto-fill, minmax(300px, 1fr))" : "1fr"};
    align-self: center;
    justify-self: center;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerText = styled.div`
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// activeFilter here is only being used to order upcoming tournaments in the opposite order
export default function TournamentList({
  tournamentList,
  isLoading,
  showFinishedTournaments = true,
  activeFilter = "All",
}) {
  const headers = useGetSecHeaders();
  const tournamentLength = tournamentList.length;

  const TournamentsListView = () => {
    let tl = [...tournamentList];
    // Tournament list on main lobby page
    return tournamentList.length < 1 ? (
      <NoTournamentFound />
    ) : (
      tl.map(item => {
        const {
          name,
          description,
          endDate,
          startDate,
          sponsored,
          poolPrize,
          poolStatus,
          isPaid,
          id,
          winner,
          tokenName,
          players,
          entrance,
          leaderboard,
          tokenImage,
          tokenSymbol,
          assets: { poolSponsored, poolThumbnail, tournCardLogo },
          entranceTokenImage,
          entranceTokenSymbol,
          joinMode,
          game,
          winnersPrize,
          maxPlayers,
        } = item;

        const formattedCurr = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(poolPrize);
        const formattedStartDate = moment(startDate).format("MMM DD, h:mm A");
        if (!showFinishedTournaments && (poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"))
          return null;
        return sponsored ? (
          <PromotedTournamentCard
            key={id}
            image={poolSponsored}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolPrize={poolPrize}
            poolName={name}
            description={description}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            endDate={endDate}
            winner={winner}
            tokenName={tokenName}
            players={players}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            poolStatus={poolStatus}
            headers={headers}
            tokenImage={tokenImage}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="promoted-tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
          />
        ) : (
          <TournamentCard
            key={id}
            image={poolThumbnail}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolName={name}
            description={description}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            endDate={endDate}
            winner={winner}
            tokenName={tokenName}
            players={players}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            tokenImage={tokenImage}
            poolStatus={poolStatus}
            headers={headers}
            poolPrize={poolPrize}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
            tournCardLogo={tournCardLogo}
          />
        );
      })
    );
  };
  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <Loader animation="rotate" width={120} />
        </LoaderWrapper>
      ) : (
        <TournamentListContainer length={tournamentLength}>
          <TournamentsListView />
        </TournamentListContainer>
      )}
    </>
  );
}
