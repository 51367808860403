import React, { useRef, useState } from "react";
import { TwitchPlayer } from "react-twitch-embed";
import styled from "styled-components";

import Modal from "../../Modal";
import Flex from "../../common/Flex";

const StreamWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.h2`
  color: #ffffff;
`;

const LiveWrapper = styled.div`
  position: relative;
`;

const LiveIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 2px;
  background-color: #eb0400;
  border: 2px solid #342788;
  width: 24.75px;
  height: 14px;
  padding: 0;
  gap: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: #ffffff;
    width: 18px;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
  }
`;

const StreamBtn = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid ${props => (props.isSelected ? "#be7eff" : "#4e3caa")};
  background-color: transparent;
  background-image: url(${props => (props.bg ? props.bg : "https://placehold.co/50x50")});
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
`;

export const StreamModal = ({ showModal, toggleModal, channelList = [] }) => {
  const [selectedChannel, setSelectedChannel] = useState(channelList[0]);

  const embed = useRef(); // We use a ref instead of state to avoid rerenders.

  const handleReady = e => {
    embed.current = e;
  };

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <StreamWrapper>
        <Title>Watching stream from {selectedChannel?.userName} </Title>
        <Flex direction="column" rowGap="10">
          <TwitchPlayer channel={selectedChannel?.userName} allowFullscreen={true} onReady={handleReady} />
          {channelList?.length >= 1 && (
            <Flex columnGap="10">
              {channelList?.map((channel, index) => (
                <LiveWrapper key={index}>
                  <StreamBtn
                    type="button"
                    onClick={() => {
                      setSelectedChannel(channel?.userName);
                    }}
                    bg={selectedChannel?.userProfileThumbnailUrl}
                    isSelected={selectedChannel === channel?.userName}
                  />
                  <LiveIndicator>
                    <span>LIVE</span>
                  </LiveIndicator>
                </LiveWrapper>
              ))}
            </Flex>
          )}
        </Flex>
      </StreamWrapper>
    </Modal>
  );
};
