/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import PromotedTournamentCard from "./PromotedTournamentCard";
import TournamentCard from "./TournamentCard";
// import * as S from "./styles";
import { useGetSecHeaders } from "../../../hooks/SecurityHeaders";
import Loader from "../Loader";
import { breakpoints } from "../../../themes";
import Lottie from "react-lottie";

import rotateAnimation from "../../../assets/lotties/rotate_animation.json";
import NoTournamentFound from "./NoTournamentFound";

const TournamentListContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  min-height: 325px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${breakpoints.up(breakpoints.tablet)} {
    display: grid;
    grid-template-columns: ${props =>
      !props.isLoading && props.length >= 1 ? "repeat(auto-fill, minmax(300px, 1fr))" : "1fr"};
    align-self: center;
    justify-self: center;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerText = styled.div`
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default function TournamentList({ tournamentList, isLoading, showFinishedTournaments, activeFilter }) {
  const headers = useGetSecHeaders();
  const tournamentLength = tournamentList.length;

  const [displayNum, setDisplayNum] = useState(10);

  const bottomBoundary = useRef(null);

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      setDisplayNum(num => num + 10);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (bottomBoundary.current) {
      observer.observe(bottomBoundary.current);
    }
  }, []);

  const TournamentsListView = () => {
    // Sorting tournament pools
    let tl = [...tournamentList];

    tl = tl.sort((t1, t2) => {
      // Ensures "RUNNING" pools show up first, a/c to ascending order
      if (t1.poolStatus === "RUNNING" && t2.poolStatus === "RUNNING") {
        return new Date(t1.startDate) - new Date(t2.startDate);
      }
      if (t1.poolStatus === "RUNNING") {
        return -1;
      }
      if (t2.poolStatus === "RUNNING") {
        return 1;
      }

      // Ensures NOT_RUNNING (upcoming) pools are ordered in ASCENDING order
      if (t1.poolStatus === "NOT_STARTED" && t2.poolStatus === "NOT_STARTED") {
        return new Date(t1.startDate) - new Date(t2.startDate);
      }

      // Ensures that NOT_RUNNING (upcoming) pools are ordered before FINISHED pools
      if (
        t1.poolStatus === "NOT_STARTED" &&
        (t2.poolStatus === "FINISHED_COLLECTING_DATA" || t2.poolStatus === "FINISHED" || t2.poolStatus === "ARCHIVED")
      ) {
        return -1;
      }
      if (
        t2.poolStatus === "NOT_STARTED" &&
        (t1.poolStatus === "FINISHED_COLLECTING_DATA" || t1.poolStatus === "FINISHED" || t1.poolStatus === "ARCHIVED")
      ) {
        return 1;
      }

      // For FINISHED tournaments, showing tournaments that started more recently (DESCENDING Order)
      return new Date(t2.startDate) - new Date(t1.startDate);
    });
    // Tournament List on '/pool' page
    return tournamentList.length < 1 ? (
      // <ContainerText>No Tournaments Found</ContainerText>
      <NoTournamentFound activeFilter={activeFilter} />
    ) : (
      tl.map((item, ind) => {
        const {
          name,
          description,
          endDate,
          startDate,
          sponsored,
          poolPrize,
          poolStatus,
          isPaid,
          id,
          winner,
          tokenName,
          players,
          entrance,
          leaderboard,
          tokenImage,
          tokenSymbol,
          assets: { poolSponsored, poolThumbnail, tournCardLogo },
          entranceTokenImage,
          entranceTokenSymbol,
          joinMode,
          game,
          winnersPrize,
          maxPlayers,
        } = item;

        const formattedCurr = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(poolPrize);
        const formattedStartDate = moment(startDate).format("MMM DD, h:mm A");
        if (ind > displayNum) return null;
        if (!showFinishedTournaments && poolStatus === "ARCHIVED") return null;
        return sponsored ? (
          <PromotedTournamentCard
            key={id}
            image={poolSponsored}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolPrize={poolPrize}
            brandLogoText={name}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            endDate={endDate}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            poolStatus={poolStatus}
            headers={headers}
            tokenImage={tokenImage}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="promoted-tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
          />
        ) : (
          <TournamentCard
            key={id}
            image={poolThumbnail}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolName={name}
            description={description}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            endDate={endDate}
            winner={winner}
            tokenName={tokenName}
            players={players}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            tokenImage={tokenImage}
            poolStatus={poolStatus}
            headers={headers}
            poolPrize={poolPrize}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
            tournCardLogo={tournCardLogo}
          />
        );
      })
    );
  };
  return (
    <>
      <TournamentListContainer length={tournamentLength} isLoading={isLoading}>
        {isLoading ? (
          <LoaderWrapper>
            <Loader animation="rotate" width={120} height={120} />
          </LoaderWrapper>
        ) : (
          <TournamentsListView />
        )}
      </TournamentListContainer>
      <div id="page-bottom-boundary" ref={bottomBoundary} />
    </>
  );
}
