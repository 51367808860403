import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useMedia from "use-media";
import { OkTick } from "../../../../assets/svg";
import { capitalizeFirstLetter } from "../../../../helpers/utils";
import { Button } from "../../common/Button";
import Checkbox from "../../common/Checkbox";
import Loader from "../../Loader";
import Modal from "../../Modal";
import * as S from "./styles";

const DynamicField = ({ fieldData, onChange, errMap }) => {
  const { title, type = "any", value = "", options = [], length, placeholder, error } = fieldData;
  const typeLowerCase = type.toLowerCase();

  if (["any", "number", "string"].includes(typeLowerCase)) {
    return (
      <S.Input
        placeholder={placeholder}
        maxLength={length}
        type={type == "number" ? "number" : "text"}
        height="42px"
        name={title}
        defaultValue={value}
        onChange={onChange}
        width="273px"
        hasError={errMap[title]}
      />
    );
  } else if (typeLowerCase === "select") {
    return (
      <S.Select
        name={title}
        defaultValue={value}
        onChange={onChange}
        width="273px"
        height="42px"
        hasError={errMap[title]}
      >
        {!value && <option value="" disabled hidden></option>}
        {options.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </S.Select>
    );
  } else if (typeLowerCase === "checkbox" || typeLowerCase == "boolean") {
    return (
      <div style={{ marginLeft: "1em", width: "265px" }}>
        <Checkbox name={title} defaultChecked={value} onChange={onChange} hasError={errMap[title]} />
      </div>
    );
  } else {
    return <div />;
  }
};
const JoinFormModal = ({ showModal, toggleModal, dynamicFormJoinPool, joinStepsReqObj }) => {
  const [step, setStep] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errMap, setErrMap] = useState({});

  const history = useHistory();
  const { id } = useParams();
  const isMobile = useMedia({ maxWidth: "768px" });

  const [formFields, setFormFields] = useState([]);
  const [userValidationData, setUserValidationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onCloseCallback = () => {
    // setFormFields([]);
    // setUserValidationData([]);
    setIsLoading(false);
    setHasSubmitted(false);
    setFormFields(joinStepsReqObj.joinReqData?.formFields);
    setUserValidationData(joinStepsReqObj.userProfileValidateData?.validateFields);
    setStep(0);
    setErrMap({});
  };

  const handleError = () => {
    console.log("Error occured while submitting form data");
    setHasSubmitted(false);
    setIsLoading(false);
  };
  useEffect(() => {
    if (joinStepsReqObj.userProfileValidateData && joinStepsReqObj.joinReqData.formFields) {
      setFormFields(joinStepsReqObj.joinReqData?.formFields);
      setUserValidationData(joinStepsReqObj.userProfileValidateData?.validateFields);
    }
  }, [joinStepsReqObj.address]);

  const handleChange = e => {
    let temp = [...JSON.parse(JSON.stringify(formFields))];
    let changedFieldIndx = temp.findIndex(item => item.title === e.target.name);
    temp[changedFieldIndx] = {
      ...temp[changedFieldIndx],
      value: e.target.type == "checkbox" ? e.target.checked : e.target.value,
    };
    setFormFields(temp);
  };

  const handleSubmit = async () => {
    setHasSubmitted(true);
    setIsLoading(true);
    try {
      let res = await dynamicFormJoinPool(
        {
          ...joinStepsReqObj,
          joinReqData: {
            ...joinStepsReqObj.joinReqData,
            formFields,
          },
        },
        handleError,
      );
      if (res?.data?.joinReqData?.formFields) {
        let errMapTemp = {};
        res.data.joinReqData.formFields.forEach(item => {
          if (item.error) {
            errMapTemp = { ...errMapTemp, [item.title]: true };
          }
        });
        setErrMap(errMapTemp);
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setHasSubmitted(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        showModal={showModal}
        toggleModal={() => toggleModal(onCloseCallback)}
        header={
          <S.TopContainer>
            <S.HeaderContainer>
              <S.JoinMFormModalHeader>
                {step === 0
                  ? capitalizeFirstLetter(joinStepsReqObj?.userProfileValidateData?.title || "Is this your account?")
                  : capitalizeFirstLetter(joinStepsReqObj?.joinReqData?.title || "Data Needed")}
              </S.JoinMFormModalHeader>
            </S.HeaderContainer>
            <S.SubText>
              {step === 0
                ? capitalizeFirstLetter(
                    joinStepsReqObj.userProfileValidateData?.subtitle || "Confirm your account handles",
                  )
                : capitalizeFirstLetter(joinStepsReqObj.joinReqData?.subtitle || "Please complete the following form")}
            </S.SubText>
          </S.TopContainer>
        }
        customStyles={{ width: "745px", maxHeight: "687px" }}
        mobileStyles={{ width: "90%" }}
        centerHeader
      >
        {isLoading ? (
          <S.LoaderWrapper>
            <Loader animation="rotate" width={100} />
          </S.LoaderWrapper>
        ) : (
          <>
            {step == 0
              ? userValidationData.length > 0 && (
                  <S.Flex flexDirection="column">
                    <S.Flex>
                      <S.ValidationGroup>
                        {userValidationData.map((item, index) => (
                          <S.FieldContainer key={index} gap="25px" alignItems="center">
                            <S.Label color="#fff" fontSize="14px" textAlign="end">
                              {item.name}
                            </S.Label>
                            <S.Input width="273px" disabled value={item.value} />
                          </S.FieldContainer>
                        ))}
                      </S.ValidationGroup>
                    </S.Flex>
                    <S.Flex justifyContent="center" alignItems="center" gap="15px" style={{ marginTop: "58px" }}>
                      <Button
                        height="40px"
                        borderRadius="30px"
                        background="transparent"
                        fontSize="15px"
                        onClick={() => {
                          history.push(`/profile?poolId=${id}`);
                        }}
                      >
                        <div style={{ textDecoration: "underline", fontSize: "16px", opacity: "0.8" }}>
                          No, change it
                        </div>
                      </Button>
                      <Button
                        height="44px"
                        onClick={() => setStep(1)}
                        borderRadius="30px"
                        background="linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                        fontSize="15px"
                        width={isMobile ? "120px" : "180px"}
                      >
                        Yes, correct
                      </Button>
                    </S.Flex>
                  </S.Flex>
                )
              : formFields.length > 0 && (
                  <>
                    <S.DynamicFieldsContainer>
                      <S.ValidationGroup>
                        {formFields.map((item, index) => (
                          <S.FieldContainer gap="25px" alignItems="center">
                            <S.Label color="#fff" fontSize="14px" textAlign="end">
                              {item.title}
                            </S.Label>
                            <div style={{ justifySelf: "baseline" }}>
                              <DynamicField onChange={handleChange} fieldData={item} errMap={errMap} />
                            </div>
                          </S.FieldContainer>
                        ))}
                      </S.ValidationGroup>
                    </S.DynamicFieldsContainer>
                    <div
                      style={{
                        marginTop: "27px",
                        marginBottom: "27px",
                        display: "flex",
                        marginBottom: "73px",
                        marginTop: "53px",
                      }}
                    >
                      <Button
                        disabled={hasSubmitted || isLoading}
                        onClick={handleSubmit}
                        width="180px"
                        height="44px"
                        borderRadius="30px"
                        fontSize="15px"
                        background="linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                        style={{ padding: "0 1.5rem", margin: "auto" }}
                      >
                        Continue
                      </Button>
                    </div>
                  </>
                )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default JoinFormModal;
