import React from "react";
import styled from "styled-components";
import { Button } from "../../common/Button";

const FilterBtn = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F338C")};
  border-radius: 30px;
  height: 37px;
  flex-shrink: 0;
  padding: 0px 20px;
  font-size: 14px;
  text-transform: capitalize;
`;

export const FilterButtons = ({ filterOfButtons = [], activeFilter, func, disabled }) => {
  // TODO: add that it handles the skeletons
  return filterOfButtons.map(item => (
    <FilterBtn key={item} active={activeFilter === item} onClick={() => func(item)} disabled={disabled}>
      {item}
    </FilterBtn>
  ));
};
