/* eslint-disable no-unused-vars */
import styled, { keyframes } from "styled-components";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { Button } from "../../common/Button";
import { breakpoints } from "../../../../themes";

export const TournamentHeaderContainer = styled.div`
  margin-bottom: 32px;
  overflow: hidden;
`;

export const HeaderTitle = styled.p`
  font-size: 26px;
  line-height: 35px;
  color: white;
  margin-bottom: 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  color: white;
  gap: 15px;
`;

export const FilterTitle = styled.p`
  font-size: 14px;
  line-height: 36px;
  margin-bottom: 23px;
  color: white;
  font-weight: bold;
`;

export const PendingBanner = styled.div`
  display: flex;
  background: #7265c8;
  margin-bottom: 20px;
  border-radius: 6px;
  width: max-content;
  padding: 12px 16px;
  p {
    color: #ffffff;
    margin: 0px;
    margin-left: 8px;
    margin-right: 16px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const MainWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 280px;
  position: relative;
  @media (max-width: 450px) {
    background: #6c47ff;
    border-radius: 10px;
    height: 300px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(27, 7, 71, 0.6) 0%, rgba(0, 0, 0, 0) 55.94%);
  border-radius: 10px;
  position: absolute;
  top: 0px;
  padding: 28px 0px 0px 28px;
  z-index: 2;

  ${breakpoints.down(breakpoints.mobileL)} {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    padding: 18px 23px 16px 23px;
    height: 100%;
  }
`;

export const BgImage = styled.img`
  width: 100%;
  height: 100%;
  @media (max-width: 450px) {
    display: none;
  }
  border-radius: 10px;
`;

export const Img = styled.img`
  position: absolute;
  width: 626px;
  height: 330px;
  bottom: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 450px) {
    display: none;
  }
`;

export const TokenImg = styled.img`
  width: 16px;
  height: 14px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const IconWrapper = styled.span`
  margin-right: 5px;
  svg {
    height: 12px;
    width: 16.85px;
  }
`;
export const TimeAndIconsWrapper = styled.div`
  display: flex;
  gap: 6.5px;
  min-width: 220px;
  @media (max-width: 450px) {
    margin-right: 20px;
  }
  align-items: center;
`;

export const TimeWrapper = styled.div`
  width: 70.5px;
  height: 30px;
  border-radius: 4px;
  background-color: #f3bcb2;
  padding: 7px 0 7px 14px;
  display: ${props => (props.showTimer ? "block" : "none")};
`;

export const TimeText = styled.p`
  font-size: 11px;
  line-height: 17px;
  color: ${props => props.theme.darkBackground};
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CurrencyWrapper = styled.div`
  margin-left: 56px;
  margin-top: 53px;

  span {
    font-size: 50px;
    line-height: 36px;
    color: white;
  }
`;

export const Joined = styled.div`
  margin-left: 56px;
  margin-top: 16px;
  font-size: 15px;
  line-height: 20px;
  color: white;
`;

export const ReadyForBattle = styled.div`
  margin-left: 56px;
  margin-top: 4px;
  font-size: 15px;
  line-height: 20px;
  color: white;
`;

export const Leave = styled(Button)`
  width: 100px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 350;
`;

export const ButtonAndLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 21px;
  align-items: center;

  a {
    color: white;
    font-size: 14px;
    line-height: 20px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonHideShowWrapper = styled.div`
  display: ${props => (props.showButton ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  align-items: center;
  transition: all 0.3s ease-in-out;
`;

export const hover = keyframes`
	50% {
		transform: translateY(-3px);
	}

	100% {
		transform: translateY(-6px);
	}
`;

export const CriptoIconWrapper = styled.div`
  display: inline-block;
  transition-duration: 0.5s;
  transition-property: transform;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  &:hover {
    transform: translateY(-6px);
    animation-name: ${hover};
    animation-duration: 1.5s;
    animation-delay: $defaultDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
`;

export const DetailsText = styled.span`
  font-size: 11px;
  line-height: 17px;
  color: white;
  align-self: center;
  margin-left: 5px;
`;

export const TimeDetailsText = styled.span`
  font-size: 11px;
  line-height: 17px;
  color: white;
  align-self: center;
  text-transform: uppercase;
`;

export const PlayerIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 16px;
  align-self: center;
  @media (max-width: 450px) {
    margin-left: 0px;
  }
`;

export const FinishedWrapper = styled.div`
  height: 30px;
  width: 57px;
  background-color: #ae4747;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  p {
    margin: 0px;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    line-height: 14px;
  }
`;

export const PlayingWrapper = styled.div`
  height: 30px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  }
  div {
    width: 12px;
    height: 12px;
    background-color: transparent;
    opacity: 0.8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    span {
      width: 7px;
      height: 7px;
      background: #c33b3b;
      border-radius: 50px;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
      transform: scale(1);
      animation: pulse 1.4s infinite;
      @keyframes pulse {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        }
        40% {
          transform: scale(1);
          box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
        }
        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }
  }
`;

export const JoinButton = styled(Button)`
  border-radius: 30px;
  font-weight: ${props => (props.active ? "bold" : "normal")};

  background-color: ${props => (props.disabled ? "grey" : "#ffffff")};
  background-image: unset;
  color: #272054;
  box-shadow: 0px 4px 4px rgba(49, 30, 88, 0.3);
  ${breakpoints.down(breakpoints.tablet)} {
    margin-right: 0;
  }
`;

export const LinkText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: underline;
  margin-bottom: 0;
  :hover {
    cursor: pointer;
  }
`;

export const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.8;
    padding: 4px;
    width: 33px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 11px;
    line-height: 17px;
    margin: 0px;
  }
  small {
    color: white;
  }
`;

export const TimeDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 150px;
`;

export const PoolStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: -10px;
`;

export const PoolStatus = styled.div`
  font-size: 11px;
  line-height: 17px;
  padding: 5px;
  border-radius: 4px;
  background: rgb(0, 0, 0, 0.085);
  width: fit-content;
  color: white;
  align-items: center;
  display: flex;
`;

export const PoolRegistrationOpened = styled.div`
  color: rgba(255, 248, 248, 0.5);
  font-size: 13px;
  line-height: 20px;
`;

export const PoolName = styled.p`
  font-size: 20px;
  line-height: 36px;
  color: white;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const PrizeWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

export const TextBlock = styled.div`
  border-radius: 8px;
  padding: 5px 12px;
  background: rgb(0, 0, 0, 0.085);
  height: 60px;
  color: white;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const BlockDescription = styled.p`
  color: #ffffff7a;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
`;

export const BlockContent = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 20px;
  line-height: 30px;
  color: white;
  margin-bottom: 0px;
`;

export const BottomRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 450px) {
    margin-top: 50px;
    padding-right: 20px;
  }
`;

export const BottomLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

export const CurrencySmall = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
`;

export const TeamWrapper = styled.div`
  display: flex;
`;

export const ShareBtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: ${props => (props.isFinished ? "0px" : "20px")};
`;

export const ShareBtn = styled.button`
  background-color: rgba(63, 47, 170, 0.8);
  border-radius: 6px;
  color: #fff;
  border: none;
  width: 52px;
  height: 33px;
  cursor: pointer;
`;
