import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { AddIcon, SearchBg, TeamDefaultAvatar, WarningIcon } from "../../assets/svg";
import TeamsModal from "../../components/v2/Teams/TeamsModal";
import MyTeamCard from "../../components/v2/Teams/MyTeamCard";
import PlayerInviteModal from "../../components/v2/Teams/PlayerInviteModal";
import { breakpoints } from "../../themes";
import callAxios from "../../hooks/Axios";
import { ENDPOINT } from "../../constants/endpoints";
import { useGetSecHeaders } from "../../hooks";
import { useWindowSize } from "../../hooks/useWindowSize";
import TeamBanner from "../../assets/team-banner.png";
import FindTeamContainer from "../../components/v2/Teams/FindTeam";
import { Button } from "../../components/v2/common/Button";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import TeamTotalRequests from "../../components/v2/Teams/TeamTotalRequests";
import { RequestContext } from "../../providers/RequestProvider";
import Flex from "../../components/v2/common/Flex";
import Avatar from "../../components/v2/common/Avatar";
import useCallAxios from "../../hooks/useAxiosCall";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_OPTIONS } from "../../constants";
import { Text } from "../../components/v2/common/Text";
import { Link } from "react-router-dom";
import moment from "moment";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }
`;

const TeamGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.isLoading === true ? "1fr" : "repeat(auto-fill, minmax(552px, 1fr))")};
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  gap: 27px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LinkButton = styled.button`
  color: #be7eff;
  text-decoration: underline;
  background: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

const RequestGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const InviteRow = ({ req, headers, acceptJoinInvite, isMobile = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <S.InviteContainer>
      <Flex alignItems="center" columnGap="24">
        {req.thumbnail ? <Avatar size="66.99px" src={req?.thumbnail} /> : <TeamDefaultAvatar />}
        <Text fontSize="18px" style={{ marginBottom: 0 }}>
          You have been invited to join{" "}
          <Link to={`team-profile/${req.teamId}`} style={{ textDecoration: "underline" }}>
            {req.name}
          </Link>
        </Text>
      </Flex>
      <Flex alignItems="center" columnGap={isMobile ? "2" : "13"} extraStyles={{ "margin-right": "34.31px" }}>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <>
            <Button
              paddingLeft={isMobile ? "6px" : "16px"}
              paddingRight={isMobile ? "12px" : "16px"}
              background="transparent"
              color="#877ADD"
              fontSize="16px"
              style={{ textDecoration: "underline" }}
              onClick={() => acceptJoinInvite(req.teamId, "rejected", setIsLoading)}
            >
              Deny
            </Button>
            <Button
              fontSize="16px"
              width={isMobile ? "auto" : "105px"}
              height="36px"
              background="#6C47FF"
              borderRadius="18.5px"
              onClick={() => acceptJoinInvite(req.teamId, "accepted", setIsLoading)}
            >
              Accept
            </Button>
          </>
        )}
      </Flex>
    </S.InviteContainer>
  );
};

const TeamsPage = () => {
  const headers = useGetSecHeaders();
  const requestUseContext = useContext(RequestContext);
  const [activeTab, setActiveTab] = useState("All");
  const [showTeamsBanner, setShowTeamsBanner] = useState(false);
  const [displayCreateTeamModal, setDisplayCreateTeamModal] = useState(false);
  const [displayInviteModal, setDisplayInviteModal] = useState(false);
  const [hasTeams, setHasTeams] = useState(true);
  const [userTeams, setUserTeams] = useState([]);
  const [teams, setTeams] = useState([]);
  const [firstTeams, setFirstTeams] = useState([]);
  const [firstJoinedTeams, setFirstJoinedTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myTeams, setMyTeams] = useState([]);
  const [myTeamsWithRequests, setMyTeamWithRequests] = useState([]);
  const [disabledButtonOnTeams, setDisabledButtonOnTeams] = useState([]);
  const [allTeamsPageNum, setAllTeamsPageNum] = useState(1);
  const [lookingForPlayersFilter, setLookingForPlayersFilter] = useState(false);
  const { isMobile } = useWindowSize();

  const { callAxiosFunc } = useCallAxios();

  const toggleDisplayInviteModal = () => {
    setDisplayInviteModal(prev => !prev);
  };

  const toggleTeamsModal = () => {
    setDisplayCreateTeamModal(!displayCreateTeamModal);
  };

  const getAllTeamsButton = async (pageNum = 1, limit = 4) => {
    setIsLoading(true);
    setActiveTab("All");
    try {
      const response = await callAxios({
        method: "GET",
        url: `${ENDPOINT.DISPLAY_ALL_TEAMS}?page=${pageNum}&limit=${limit}`,
        headers: headers.token ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }) : null,
      });
      if (activeTab !== "Requests") {
        if (Array.isArray(response?.data)) {
          setTeams(prev => {
            return [...prev, ...response?.data];
          });
        }
      }

      const teamsWithSentRequests = response?.data?.filter(team => {
        const userHasSentRequest = team?.players?.find(
          player => player?.userId === headers?.userId && player?.status === "pending-accept",
        );
        return userHasSentRequest;
      });
      setDisabledButtonOnTeams(teamsWithSentRequests);
      setFirstTeams(response?.data.slice(0, 6));
      const teams = response?.data?.filter(team => team?.owner === headers?.userId);
      setMyTeams(teams);
    } catch (error) {
      console.log({ error });
    }
    setIsLoading(false);
  };

  const getUserTeams = async () => {
    setUserTeams([]);
    setIsLoading(true);
    try {
      const response = await callAxiosFunc({
        method: "GET",
        url: `${ENDPOINT.DISPLAY_USER_TEAMS}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      if (response.err) {
        setIsLoading(false);
        return;
      }
      if (activeTab !== "Requests") {
        setUserTeams(response?.data);
      }
      setFirstJoinedTeams(
        response?.data?.sort((a, b) => (moment(a.createdOn).isAfter(moment(b.createdOn)) ? -1 : 1))?.slice(0, 6),
      );
      if (response?.data?.length > 0) {
        setHasTeams(true);
        setShowTeamsBanner(false);
        const teams = response?.data?.filter(team => team?.owner === headers?.userId);
        setMyTeams(teams);
      } else {
        setHasTeams(false);
        setShowTeamsBanner(true);
      }
    } catch (error) {
      console.log({ error });
      setHasTeams(false);
    }
    setIsLoading(false);
  };

  const acceptJoinInvite = async (teamIdArg, status, setLoader = () => {}) => {
    const { setUserTeamsRequests, userTeamsRequests, totalRequests, setTotalRequests } = requestUseContext;
    setLoader(true);
    const response = await callAxiosFunc({
      method: "POST",
      url: `team/join/${teamIdArg}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ status }),
    });
    setLoader(false);

    if (response && response.err) {
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />{" "}
          {response?.err?.response?.data?.message
            ? response?.err?.response?.data?.message?.[0]
            : "Something went wrong. Please try again!"}
        </Flex>,
        TOAST_OPTIONS,
      );
      return { err: response.err };
    } else {
      setUserTeamsRequests(userTeamsRequests?.filter(req => req.teamId !== teamIdArg));
      if (totalRequests > 0) {
        setTotalRequests(totalRequests - 1);
      }
    }
  };

  const getMyTeamsButton = async () => {
    setIsLoading(true);
    setActiveTab("MyTeams");
    try {
      const response = await callAxios({
        method: "GET",
        url: `${ENDPOINT.DISPLAY_USER_TEAMS}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      if (response.err) {
        setIsLoading(false);
        return;
      }

      // return all the teams when the owner is the same as the player?.userId
      const teams = response?.data?.filter(team => team?.owner === headers?.userId);
      setFirstJoinedTeams(teams?.slice(0, 6));
      if (activeTab !== "Requests") {
        setUserTeams(response?.data);
      }
      setMyTeams(teams);
    } catch (error) {
      console.log({ error });
    }
    setIsLoading(false);
  };

  const getRequestsButton = async () => {
    setActiveTab("Requests");
    setIsLoading(true);
    /* myTeams is an array of objects with all the teams that the user is the owner.
     * teamNotificationsFound finds all the teams where the user is the owner and there are requests to join the team. (this is for return the teams Data like name and all of that since the context doesn't return this data)
     * teamWithRequests is the team data (like name and all of that) with the teamRequestJoin prop which it's the name of requests to join the team.
     */
    for (const team of myTeams) {
      const teamNotificationsFound = requestUseContext?.userTeamRequestsToJoin.find(
        userTeamsOwnerRequest => userTeamsOwnerRequest?.teamId === team?.id,
      );

      if (teamNotificationsFound?.teamRequestJoin) {
        const teamWithRequests = { ...team, teamRequestJoin: teamNotificationsFound?.teamRequestJoin };
        setMyTeamWithRequests([teamWithRequests]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (headers.token !== null) {
      getUserTeams();
    }
  }, [headers.token]);

  useEffect(() => {
    getAllTeamsButton(allTeamsPageNum);
  }, [allTeamsPageNum]);

  const setNewTeamAfterFormSubmit = newTeam => {
    setUserTeams(prev => [newTeam, ...prev]);
    setTeams(prev => [newTeam, ...prev]);
    setFirstTeams(prev => [newTeam, ...prev]);
    setFirstJoinedTeams(prev => [newTeam, ...prev]);
  };

  // function that add 6 more teams from allTeams to firstTeams
  const addMoreTeams = () => {
    setFirstTeams(prev => [...prev, ...teams.slice(prev.length, prev.length + 6)]);
  };

  const addMoreJoinedTeams = () => {
    setFirstJoinedTeams(prev => [...prev, ...userTeams.slice(prev.length, prev.length + 6)]);
  };

  const isLoggedIn = Boolean(headers && headers.token);

  return (
    <>
      <ToastContainer />
      {isLoading && !userTeams ? (
        <SpinnerLoaderWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : (
        <MainContainer>
          <S.Container>
            <S.TitleContainer>
              <S.Title>Teams</S.Title>
              {isLoggedIn && (
                <Button
                  onClick={toggleTeamsModal}
                  background="linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                  height="40px"
                  width="120px"
                  fontWeight="500"
                  borderRadius="30px"
                  fontSize="14px"
                  data-testid="create-team-button"
                  id="analytics-create-team"
                >
                  <AddIcon style={{ marginRight: "10px" }} /> Create
                </Button>
              )}
            </S.TitleContainer>
            <S.FindTeamWrap>
              {showTeamsBanner || !isLoggedIn ? (
                <S.TeamContainer height="100%">
                  <S.Image src={TeamBanner} alt="Teams Banner" />
                </S.TeamContainer>
              ) : (
                <div style={{ marginTop: "10px" }} />
              )}
              {isLoggedIn && (
                <S.ButtonContainer>
                  <Button
                    // when click call getAllTeamsButton and getUserTeams to get all the teams and the teams that the user is in
                    onClick={() => {
                      getAllTeamsButton();
                      getUserTeams();
                    }}
                    background={
                      activeTab === "All" ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F2FAA"
                    }
                    width="100px"
                    height="40px"
                    fontWeight="325"
                    fontSize="14px"
                    borderRadius="30px"
                  >
                    All
                  </Button>
                  <Button
                    onClick={getMyTeamsButton}
                    background={
                      activeTab === "MyTeams"
                        ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                        : "#3F2FAA"
                    }
                    width="100px"
                    height="40px"
                    fontWeight="325"
                    fontSize="13px"
                    borderRadius="30px"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    My teams
                  </Button>
                  <Button
                    onClick={() => {
                      getRequestsButton();
                    }}
                    background={
                      activeTab === "Requests"
                        ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                        : "#3F2FAA"
                    }
                    width="120px"
                    height="40px"
                    fontWeight="325"
                    borderRadius="30px"
                    fontSize="14px"
                    style={{ position: "relative" }}
                    // if myTeams is less or equal to 0 disable the button
                    disabled={requestUseContext.totalRequests <= 0}
                  >
                    Notifications
                    {requestUseContext?.totalRequests === 0 ? null : (
                      <S.Badge>{requestUseContext?.totalRequests}</S.Badge>
                    )}
                  </Button>
                </S.ButtonContainer>
              )}
              {activeTab !== "Requests" && (
                <>
                  <S.MyTeamsContainer isLoading={isLoading}>
                    {isLoading === true ? (
                      <SpinnerLoaderWrapper>
                        <SpinnerLoader style={{ width: "60px", height: "60px" }} />
                      </SpinnerLoaderWrapper>
                    ) : (
                      <>
                        {firstJoinedTeams?.map((team, index) => (
                          <div key={index}>
                            <MyTeamCard
                              teamData={team}
                              displayInviteModal={() => setDisplayInviteModal(true)}
                              isLoading={displayInviteModal}
                            />
                            <PlayerInviteModal
                              showModal={displayInviteModal}
                              toggleModal={toggleDisplayInviteModal}
                              teamId={team?.id}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </S.MyTeamsContainer>
                  {userTeams?.length > 6 && (
                    <S.Flex justifyContent="center" alignItems="center" style={{ width: "100%", marginTop: "40px" }}>
                      {isLoading === false ? (
                        <Button
                          id="add-more-general-teams"
                          onClick={addMoreJoinedTeams}
                          background="transparent"
                          width="147px"
                          height="43px"
                          fontWeight="325"
                          fontSize="16px"
                          borderRadius="30px"
                          border="2px solid #6C47FF"
                        >
                          View More
                        </Button>
                      ) : null}
                    </S.Flex>
                  )}
                </>
              )}
              {teams.length > 0 && (
                <>
                  {activeTab !== "Requests" && (
                    <div style={{ marginTop: "30px", width: "100%" }}>
                      <S.Flex direction="column" rowGap="48px" style={{ width: "100%" }}>
                        <S.Title>Find Teams</S.Title>
                        {/* TODO: Add Search Bar */}

                        <S.TeamFilters>
                          {/* TODO: Uncomment filters once API becomes available */}
                          {/* <S.Flex columnGap="14">
                            <Button
                              height="47px"
                              borderRadius="30px"
                              padding="14px 25px"
                              onClick={() => setLookingForPlayersFilter(false)}
                              style={lookingForPlayersFilter ? { background: "#3F2FAA" } : {}}
                            >
                              All
                            </Button>
                            <Button
                              height="47px"
                              borderRadius="30px"
                              padding="14px 25px"
                              onClick={() => setLookingForPlayersFilter(true)}
                              style={!lookingForPlayersFilter ? { background: "#3F2FAA" } : {}}
                            >
                              Looking for players
                            </Button>
                          </S.Flex> */}
                          {/* TODO: Uncomment button once API becomes available */}
                          {/* <S.SearchContainer>
                            <input placeholder="Search a team"/>
                            <S.SearchBtn><SearchBg /></S.SearchBtn>
                          </S.SearchContainer> */}
                        </S.TeamFilters>
                      </S.Flex>
                      <TeamGrid length={teams.length} isLoading={isLoading && teams.length < 1}>
                        {teams.map((team, index) => (
                          <FindTeamContainer teamData={team} key={index} isDisabled={disabledButtonOnTeams} />
                        ))}
                      </TeamGrid>
                      <S.Flex justifyContent="center" alignItems="center" style={{ width: "100%", marginTop: "40px" }}>
                        <Button
                          id="add-more-general-teams"
                          onClick={() => setAllTeamsPageNum(allTeamsPageNum + 1)}
                          background="transparent"
                          width="147px"
                          height="43px"
                          fontWeight="325"
                          fontSize="16px"
                          borderRadius="30px"
                          border="2px solid #6C47FF"
                          disabled={isLoading}
                        >
                          {isLoading ? <SpinnerLoader /> : "View More"}
                        </Button>
                      </S.Flex>
                    </div>
                  )}
                </>
              )}
              {activeTab === "Requests" ? (
                <>
                  <S.Flex direction="column" style={{ width: "100%" }}>
                    <S.Title>Requests</S.Title>
                  </S.Flex>
                  {isLoading === true ? (
                    <SpinnerLoaderWrapper>
                      <SpinnerLoader style={{ width: "60px", height: "60px" }} />
                    </SpinnerLoaderWrapper>
                  ) : (
                    <RequestGrid>
                      {myTeamsWithRequests?.length > 0 ? (
                        <>
                          {myTeamsWithRequests?.map((item, index) => (
                            <TeamTotalRequests item={item} key={index} isMobile={isMobile} />
                          ))}
                        </>
                      ) : (
                        <S.Text>You don&apos;t have any requests</S.Text>
                      )}
                    </RequestGrid>
                  )}

                  {/* INVITES!! */}

                  <Flex direction="column" style={{ width: "100%" }} extraStyles={{ marginTop: "12px" }}>
                    <S.Title>Invites</S.Title>
                  </Flex>
                  {isLoading === true ? (
                    <SpinnerLoaderWrapper>
                      <SpinnerLoader style={{ width: "60px", height: "60px" }} />
                    </SpinnerLoaderWrapper>
                  ) : (
                    <>
                      {requestUseContext?.userTeamsRequests?.length > 0 ? (
                        <div style={{ marginTop: "14px", width: "100%" }}>
                          {requestUseContext?.userTeamsRequests?.map(req => (
                            <InviteRow
                              req={req}
                              headers={headers}
                              acceptJoinInvite={acceptJoinInvite}
                              isMobile-={isMobile}
                            />
                          ))}
                        </div>
                      ) : (
                        <S.Text>You don&apos;t have any invites</S.Text>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </S.FindTeamWrap>

            <TeamsModal
              showModal={displayCreateTeamModal}
              toggleModal={toggleTeamsModal}
              setNewTeamData={setNewTeamAfterFormSubmit}
            />
          </S.Container>
        </MainContainer>
      )}
    </>
  );
};

export default TeamsPage;
