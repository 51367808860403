import React from "react";
import * as S from "../styles";
import PlayerRow from "../PlayerRow/PlayerRow";
import Pagination from "../../../Pagination";
import img1 from "../../../../../assets/leaderboard-img-1.png";
import img2 from "../../../../../assets/leaderboard-img-2.png";
import img3 from "../../../../../assets/leaderboard-img-3.png";
import { EPHERE_GAME_ID } from "../../../../../constants";
import { LEADERBOARD_PAGE_LIMIT, LEADERBOARD_ROWS, MOBILE_LEADERBOARD_PAGE_LIMIT } from "../../../../../constants/env";

const images = [img1, img2, img3];
const randomIndex = Math.floor(Math.random() * 2);

export const DynamicLeaderboard = ({
  dynamicHeaders = [],
  leaderBoardInfo,
  isMobile,
  gameId,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
  isLoading,
}) => {
  const colCountMap = {
    [EPHERE_GAME_ID]: 12,
  };

  const dynamicLeaderboardData = leaderBoardInfo?.map(data => ({
    ...data,
    dynamicHeaders,
    colCountMap,
  }));

  return dynamicHeaders?.length > 0 ? (
    <>
      <S.RowHeaderWrapper colCount={colCountMap[gameId] || dynamicHeaders.length} isMobile={isMobile}>
        {gameId === EPHERE_GAME_ID ? (
          <S.HeaderText style={{ gridColumn: "span 3" }}>Name</S.HeaderText>
        ) : (
          <>
            <S.HeaderText>Ranking</S.HeaderText>
            <S.HeaderText>Name</S.HeaderText>
          </>
        )}
        {!isMobile ? (
          <>
            {dynamicHeaders.map(item => (
              <S.HeaderText>{item?.dataTitle || item?.value || item?.key}</S.HeaderText>
            ))}
          </>
        ) : (
          <S.HeaderText>{dynamicHeaders[0]?.dataTitle || dynamicHeaders[0]?.value || dynamicHeaders[0]?.key}</S.HeaderText>
        )}
      </S.RowHeaderWrapper>
      <Pagination
        data={dynamicLeaderboardData}
        isMobile={isMobile}
        RenderComponent={props => (
          <PlayerRow
            dynamicHeaders={dynamicHeaders}
            item={props}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
            isLoading={isLoading}
          />
        )}
        pageLimit={isMobile ? Number(MOBILE_LEADERBOARD_PAGE_LIMIT) || 3 : Number(LEADERBOARD_PAGE_LIMIT) || 10}
        dataLimit={Number(LEADERBOARD_ROWS) || 5}
      />
    </>
  ) : (
    <S.ImageWrapper>
      <img src={images[randomIndex]} alt="" />
    </S.ImageWrapper>
  );
};
